import styled from 'styled-components'
import { BACKGROUND, PRIMARY } from '../constants/colors'

export const Container = styled.div`
  padding-top: 50px;
  background-color: ${BACKGROUND};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FormWrap = styled.form`
  width: 350px;
`

export const FormInputDiv = styled.div`
  margin-bottom: 16px;
`

export const FormInput = styled.input`
  border: 1px solid ${PRIMARY};
  border-radius: 6px;
  font-size: 18px;
  text-align: left;
  color: ${PRIMARY};
  height: 40px;
  background-color: transparent;
  width: 100%;
  padding-left: 8px;

  ::placeholder {
    color: ${PRIMARY};
    opacity: 0.8;
  }
`

export const SubmitButton = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 40px;
  margin: 20px 0px;
  border: 1px solid ${PRIMARY};

  cursor: pointer;
`

export const SubmitText = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
`

export const HeaderIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid ${PRIMARY};
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-bottom: 26px;
`

export const SecondaryText = styled.div`
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 23px;
  color: ${PRIMARY};
  width: 350px;
`

export const SpinnerWrap = styled.div`
  position: absolute;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`
